<template>
    <v-footer
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        
        class="orange accent-4 lighten-1 text-center"
      >
        <v-card-text>
          <v-btn
            v-for="(icon, index) in icons"
            :key="'icon_' + index"
            class="mx-4"
            icon
            :href="icon.href"
            target="_blank"
            
          >
            <v-icon size="24px" class="white--text">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="white--text">
          Santa Clara del Mar - Provincia de Buenos Aires 
          <br>
          {{ new Date().getFullYear() }} — <strong>Chelimar</strong>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
export default {
  name: 'Footer',

  data: () => ({
    //
    icons: [
      {
        icon: 'mdi-instagram',
        href: 'https://www.instagram.com/chelimar.alimentos/',
      },
      {
        icon: 'mdi-facebook',
        href: 'https://www.facebook.com/profile.php?id=100086253197342',
      },
      {
        icon: 'mdi-whatsapp',
        href: 'https://wa.me/5492233390278',
      },
      
    ],
    padless: false,
  }),
};
</script>