export const dataGallery = [
    {
        id: 1,
        titulo: 'Linea Mar',
        subtitulo: 'Linea Mar',
        texto: 'En nuestra linea mar vas a encontrar Filet de merluza rebozado, Filet de merluza rebozado a las finas hierbas, Filet de merluza a la romana, Formitas de merluza, Medallón de merluza y más. ',
        imagen: '/images/langostinos_3.jpg',
    },
    {
        id: 2,
        titulo: 'Linea Granja',
        subtitulo: 'Linea Granja',
        texto: 'En nuestra linea granja vas a encontrar Medallónes de pollo, Patitas de pollo, Patitas de pollo con jamón y queso, Nuggets de Pollo, Bastones de pollo con cheddar, pechuguitas de pollo al verdeo y Medallones de pollo con espinaca.',
        imagen: '/images/nuggets_1.jpg',
    },
    {
        id: 3,
        titulo: 'Linea Huerta',
        subtitulo: 'Linea Huerta',
        texto: 'En nuestra linea huerta vas a encontrar Croquetas de espinaca, Croquetas de papa, jamón y queso, Croquetas de zapallo y queso, Papas Bastón, Caritas de papa, Papas noissette y Bastones de mozzarella.',
        imagen: 'images/croquetas_papa_2.jpg',
    },
]