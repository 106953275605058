<template>
  <v-app>
    <Nav></Nav>
    <v-main>
      <router-view/>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>

import Nav from './components/partials/Nav'
import Footer from './components/partials/Footer'

export default {
  name: 'App',

  data: () => ({
    //
  }),

  components: {
    Nav,
    Footer,
  },

};
</script>
