<template>
  <div>
    <v-btn
        class="whatsapp-logo"
        fab
        x-large
        color="green accent-4"
        dark
        absolute
        elevation="8"
        right="20"
        @click="openWhatsApp"
    >
    <v-icon dark>mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'WhatsappFloating',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    openWhatsApp() {
      window.open('https://wa.me/5492233390278', '_blank');
    }
  },
};
</script>
<style>
.whatsapp-logo {
  position: fixed;
  bottom: 100px;
}
</style>