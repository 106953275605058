<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="headline">
                {{ dataModalHome[0].title }} 
            </v-card-title>
            <v-card-text
                class="font-weight-bold text-h6"
            >
                {{ dataModalHome[0].text }}
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    dark
                    @click="dialog = false"
                >
                    <v-icon left>
                        mdi-close
                    </v-icon>
                    Cerrar &nbsp;
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    name: 'ModalHome',

    props: {
        dataModalHome : {
            type: Array,
            required: true
        }
    },

    data() {
        return {
        dialog: true,
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>