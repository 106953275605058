<template>
    <v-parallax
        src="/images/rabas.jpg"
    >
        <div class="d-flex flex-column fill-height justify-center align-center text-white">
            <v-img 
                src="../../assets/images/logo.jpg"
                width="200px"
                height="auto"
                contain  
            ></v-img>
            <h1 class="subheading text-center mb-10 text-uppercase">
                {{ mensajeSlider }}
            </h1>
        </div>
  </v-parallax>
</template>

<script>
export default {
    name: 'SliderHome',

    data() {
        return {
            mensajeSlider: 'Venta online y envio a domicilio de alimentos supercongelados',
            items: [
                {
                    src: '../../images/rabas.jpg',
                },
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style scoped>
.text-container {
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */
  height: 100%;
  
  
}
.slider-opacity {
    background-color: rgb(224, 232, 239);
    opacity: 0.7;
}

</style>