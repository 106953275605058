<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="4" v-for="dh in dataHome" :key="dh.id">
                <v-card>
                    <v-card-title class="text-uppercase font-weight-black"> 
                        <v-icon
                        color="warning"
                        size="x-large"
                        >{{ dh.icon }}</v-icon>
                        &nbsp; {{ dh.titulo }}
                    </v-card-title>
                    <v-card-text class="font-weight-bold">
                        {{ dh.texto }}
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card  
                color="orange"
                variant="outlined">
                    <v-card-text class="text-center text-uppercase white--text"> 
                        Los pedidos para el mismo día los tomamos antes de las 17hs.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { dataHome } from '/data/info-home.js';

export default {
    name: 'InfoHome',

    data() {
        return {
            dataHome: dataHome,
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style scoped>

</style>