<template>
    <div>
      <v-container>
      <p class="display-3 font-weight-light	text-center pa-4">Conocé Nuestros Productos</p>
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-12" v-for="dg in dataGallery" :key="dg.id">
            <v-card
              class="mx-auto"
              max-width="400"
              outlined
              tile
            >
              <v-img
                class="white--text align-end"
                height="400px"
                :src="dg.imagen"
              >
                <v-card-title class="font-weight-black text-uppercase">{{ dg.titulo }}</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0 text-uppercase">{{ dg.titulo }}</v-card-subtitle>

              <v-card-text class="text--primary">

                <div>
                  {{ dg.texto }}
                </div>
              </v-card-text>
            </v-card>
          </div>
          
          
        </div>
    </v-container>
    </div>
</template>

<script>
import { dataGallery } from '/data/info-gallery.js';

export default {
    name: 'Gallery',

    data() {
       
        return {
          dataGallery: dataGallery,
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>