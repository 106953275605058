<template>
    <v-app-bar
      app
      color="orange"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Chelimar Logo"
          class="shrink mr-2"
          contain
          src="../../assets/images/logo.jpg"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Chelimar Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="../../assets/images/logo-name.jpg"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://chelimar.com/3-ver-productos"
        elevation="2"
        rounded
        color="blue darken-4"
      > 
      <v-icon medium class="progress">mdi-storefront</v-icon>
        &nbsp;<span>Tienda Virtual</span>
      </v-btn>
    </v-app-bar>
</template>

<script>
export default {
  name: 'Nav',

};
</script>
<style>
  @keyframes go-left-right {  
    10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
  }

  .progress {
    animation: go-left-right 2s infinite alternate;
    /* aplicar la animación "go-left-right" al elemento
       duración 3 segundos
       número de veces: infinitas
       alternar la dirección cada vez
    */
    
  }
</style>