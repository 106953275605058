<template>
  <v>
    <ModalHome :dataModalHome="dataModalHome" v-if="dataModalHome[0].active"></ModalHome>
    <SliderHome></SliderHome>
    <InfoHome></InfoHome>
    <gallery></gallery>
    <WhatsappFloating></WhatsappFloating>
  </v>
</template>

<script>
  import SliderHome from '../components/home/SliderHome';
  import InfoHome from '../components/home/InfoHome';
  import Gallery from '../components/home/Gallery';
  import ModalHome from '../components/home/ModalHome';
  import WhatsappFloating from '../components/home/WhatsappFloating.vue';

  import { dataModalHome } from '/data/info-modal-home.js';

  export default {
    name: 'Home',

    components: {
      SliderHome,
      InfoHome,
      Gallery,
      ModalHome,
      WhatsappFloating,
    },

    data() {
      return {
        dataModalHome: dataModalHome,
      };
    },
  }
</script>
