export const dataHome = [
    {
        id: 1,
        titulo: '0223-339-0278',
        texto: 'Contáctanos de lunes a sábados de 10:00 a 17:00hs o hace tu pedido por la tienda virtual.',
        icon: 'mdi-whatsapp',
    },
    {
        id: 2,
        titulo: 'Envío sin cargo',
        texto: 'En compras mayores a $8.000. Horario de entrega de 17:30 a 19hs.',
        icon: 'mdi-truck-fast-outline',
    },
    {
        id: 3,
        titulo: 'Zonas de entrega',
        texto: 'Camet Norte, Santa Clara, Mar de Cobo, La Caleta, Atlántida, Frente Mar, Santa Elena y Playa Dorada.',
        icon: 'mdi-map-marker-radius',
    }
]